// Navtoggler

document.querySelector('.headerbar__content-right-navtoggler').addEventListener('click', () => {
  document.body.classList.toggle('body--activenav');
})


// Scroll Behaviour for Headerbar

let lastScroll = 0

window.addEventListener("scroll", function() {
  // Check if Scrolled
  if (document.documentElement.scrollTop > 0) {
    document.body.classList.add('body--scrolled');
  } else {
    document.body.classList.remove('body--scrolled');
  }

  lastScroll = document.documentElement.scrollTop
});

window.addEventListener('DOMContentLoaded', () => {
  // Check if Scrolled
  if (document.documentElement.scrollTop > 0) {
    document.body.classList.add('body--scrolled');
  } else {
    document.body.classList.remove('body--scrolled');
  }
})

// Card Accordions on Mobile

document.querySelectorAll('.card--accordion').forEach((card) => {
  card.addEventListener('click', () => {
    card.classList.toggle('card--accordion--opened');
  })
});

// Initiate Parallax

pureParallax({
  minWidth: 0
});

// Filtermagic
if(document.querySelector('.filtermagictoggle')) {
  document.querySelector('.filtermagictoggle').addEventListener('click', () => {
    document.querySelector('form[name="filtermagic"]').classList.toggle('visible');
    if (document.querySelector('.filtermagictoggle').innerHTML === 'Filter anzeigen') {
      document.querySelector('.filtermagictoggle').innerHTML = 'Filter ausblenden';
    } else {
      document.querySelector('.filtermagictoggle').innerHTML = 'Filter anzeigen';
    }
  })
}

// Add Class to Toolbar after Short Delay
window.addEventListener('DOMContentLoaded', () => {
  if( document.querySelector('.toolbar')) {
    setTimeout(() => {
      document.querySelector('.toolbar').classList.add('moved-in');
    }, 500);
  }
});

// Set Real VW as Variable (Document Width without Scrollbar)
window.addEventListener('DOMContentLoaded', () => {
  document.documentElement.style.setProperty('--vw', document.body.clientWidth / 100 + 'px');
})
window.addEventListener('resize', () => {
  document.documentElement.style.setProperty('--vw', document.body.clientWidth / 100 + 'px');
})

// Set Active Class for Article-Card

document.querySelectorAll('.articlecard').forEach((card) => {
  card.addEventListener('click', () => {
    if(card.classList.contains('articlecard--active')) {
      card.classList.remove(('articlecard--active'));
      return;
    }
    document.querySelectorAll('.articlecard').forEach((card) => {
      card.classList.remove('articlecard--active')
    });
    card.classList.add('articlecard--active');
  })
});